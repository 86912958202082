'use client';

import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import debounce from 'lodash/debounce';
import { useRouter } from '../../i18n/routing';
import { AdditionalFilter } from '../../types/experts';
import { getObjectByFilter, getObjectByAdditionalFilter, getSearchParamsString } from '../../utils/filter';
import { CustomInput } from '../view/CustomInput';
import { CustomSelect } from '../view/CustomSelect';
import { i18nText } from '../../i18nKeys';

type ExpertAdditionalFilterProps = {
    basePath: string;
    locale: string;
};

export const ExpertsAdditionalFilter = ({
    basePath,
    locale
}: ExpertAdditionalFilterProps) => {
    const searchParams = useSearchParams();
    const router = useRouter();
    const [filter, setFilter] = useState<AdditionalFilter | undefined>(getObjectByAdditionalFilter(searchParams));
    const [isMounted, setIsMounted] = useState<boolean>(false);

    const onChangeInput = useCallback(debounce((e: any) => {
        const newFilter: AdditionalFilter = { ...filter };

        if (e?.target?.value) {
            newFilter.text = e.target.value;
        } else {
            delete newFilter?.text;
        }

        setFilter(newFilter);
    }, 300), [filter]);

    const onChangeSort = useCallback((value: string) => {
        const newFilter: AdditionalFilter = { ...filter };

        if (value) {
            newFilter.coachSort = value;
        } else {
            delete newFilter?.coachSort;
        }

        setFilter(newFilter);
    }, [filter]);

    const updateRoute = debounce(() => {
        const newFilter = {
            ...getObjectByFilter(searchParams),
            ...filter
        };
        const search = getSearchParamsString(newFilter);

        router.push(search ? `${basePath}?${search}#filter` : `${basePath}#filter`);

        // router.push({
        //     pathname: basePath as any,
        //     query: {
        //         ...getObjectByFilter(searchParams),
        //         ...filter
        //     }
        // })
    }, 300);

    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true);
        }
    }, []);

    useEffect(() => {
        if (isMounted) {
            updateRoute();
        }
    }, [filter]);

    return (
        <div className="main-find__search">
            <div className="main-find__search__input">
                <CustomInput
                    placeholder={i18nText('searchExpert', locale)}
                    defaultValue={filter?.text}
                    onChange={onChangeInput}
                    allowClear
                />
            </div>
            <div className="main-find__search__sort">
                <CustomSelect
                    label={i18nText('sort', locale)}
                    value={filter?.coachSort}
                    onChange={onChangeSort}
                    options={[
                        // { value: 'byTop', label: 'By top views' },
                        { value: 'byPriceAscending', label: i18nText('sortPriceAsc', locale) },
                        { value: 'byPriceDescending', label: i18nText('sortPriceDesc', locale) }
                        // { value: 'byRating', label: 'By rating' }
                    ]}
                />
            </div>
        </div>
    );
};
